<template>
  <div class="content">
    <Header v-if="showFooter || screen.width < 768" />
    <router-view></router-view>
    <Footer v-if="showFooter || screen.width < 768" />
    <div class="shadow-movil">
      <ModalAsesor v-if="openModal" class="content__modalAsesor" />
      <RegisterBtn class="content__register" />
      <InstagramBtn />
      <WhatsappBtn />
      <GalleryBtn />
    </div>
    <MobileMenu />
  </div>
</template>

<script>
  import Header from "@/components/web/Header.vue";
  import Footer from "@/components/web/Footer.vue";
  import WhatsappBtn from "@/components/web/WhatsappBtn.vue";
  import RegisterBtn from "@/components/web/RegisterBtn.vue";
  import GalleryBtn from "@/components/web/GalleryBtn.vue";
  import MobileMenu from "@/components/web/MobileMenu.vue";
  import InstagramBtn from "@/components/web/InstagramBtn.vue";
  import SocketLanding from "../socket/socketLandig";

  export default {
    components: {
      Header,
      Footer,
      RegisterBtn,
      WhatsappBtn,
      MobileMenu,
      GalleryBtn,
      InstagramBtn,
    },
    data() {
      return {
        openModal: false,
      };
    },
    computed: {
      screen() {
        return this.$store.getters.screen;
      },
      showFooter() {
        const dicRoutes = ["/signup#signup", "/registrate", "/register", "/signup", "/signup/success", "/signup/success#signup"];

        return !dicRoutes.includes(this.$route.fullPath);
      },
    },
    methods: {
      showModal(bot) {
        this.openModal = !bot;
      },
      conectSocketLanding() {
        const socket = SocketLanding.connect({});

        SocketLanding.on("connect", () => {
          console.log("Successfully connection");
          localStorage.setItem("SID", socket.id);
        });
      },
    },
    beforeMount() {
      this.conectSocketLanding();
    },
  };
</script>
<style lang="scss">
  .content {
    &__register {
      margin-bottom: 60px;
    }
    &__modalAsesor {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: -300px;
      bottom: 210px;
      z-index: 140;
    }
    .shadow-movil {
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: fixed;
      z-index: 100;
      bottom: 25px;
      right: 10px;
      transform: translateY(0px);

      &:before {
        content: "";
        background: radial-gradient(ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
        width: 92%;
        height: 20px;
        top: 100%;
        left: 2%;
        position: absolute;
        opacity: 1;
      }
    }
    @media (min-width: 800px) {
      &__modalAsesor {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        left: -300px;
        bottom: 210px;
        z-index: 140;
      }
    }
  }
</style>
